import React from 'react';
import "./Footer.css"

const Footer = () => {
  return (
    <footer id="cs-footer-274">
      <div className="cs-container">
        {/* Logo Group */}
        <div className="cs-logo-group">
          <a aria-label="go back to home" className="cs-logo" href="#">
            {/* Remove the light class if you don't need the dark logo */}
            LEARN TO FLY
          </a>
          <div className="cs-social">
            {/* <a className="cs-social-link" aria-label="visit google profile" href="#">
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg"
                alt="google"
                width="11"
                height="11"
              />
            </a>
            <a className="cs-social-link" aria-label="visit facebook profile" href="#">
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg"
                alt="facebook"
                width="6"
                height="11"
              />
            </a> */}
            <a className="cs-social-link" aria-label="visit instagram profile" href="https://www.instagram.com/justindalkjaer/">
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg"
                alt="instagram"
                width="11"
                height="11"
              />
            </a>
          </div>
        </div>

        {/* Links */}
        <ul className="cs-nav">
          <li className="cs-nav-li">
            <span className="cs-header">Contact</span>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="tel:022 051 7898">022 051 7898</a>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="mailto:justindalkjaer@gmail.com">justin.dalkjer.tyson@gmail.com</a>
          </li>
        </ul>

        {/* Address */}
        <ul className="cs-nav">
          <li className="cs-nav-li">
            <span className="cs-header">Address</span>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="#">
            67 Handforth Street, Onerahi, Whangārei 0110
            </a>
          </li>
        </ul>

        {/* Contact Info */}
        <ul className="cs-nav">
          <li className="cs-nav-li">
            <span className="cs-header">Training Resources</span>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="https://www.waypoints.nz/products/private-pilot-licence-ppl-kit">Pilot books</a>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="https://caanz.aspeqexams.com/home">Exams</a>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="https://shop.aeropath.aero/products/aip-volume-4-kit">Material (Aip vol 4 + VNC C3)</a>
          </li>
          {/* <li className="cs-nav-li">(closed for lunch 12pm-2pm)</li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
