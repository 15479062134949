import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Hero.css";
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate(); // Hook to programmatically navigate

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter an email address.");
      return;
    }

    const serviceID = 'service_gmotd25';
    const templateID = 'template_54d1x3i';
    const publicKey = 'no26ATx0bOIvBzFsy';

    const templateParams = {
      user_email: email, // Pass the email input value
      to_email: 'justin.dalkjer.tyson@gmail.com', // Replace with your desired recipient
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSent(true);
        navigate('/contact'); // Navigate to /contacts page after successful email
      })
      .catch((err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <section id="hero-229">
      <div className="cs-container">
        <div className="cs-flex-group">
          <span className="cs-topper">Want to learn to fly in Whangārei?</span>
          <h1 className="cs-title">Learn To Fly Today.</h1>
          <p className="cs-text">
          From The First Time You Step In The Plane, To The First Time You Land Solo On Great Barrier - We'll Get You There.
          </p>
        </div>
        <form onSubmit={sendEmail} className="cs-email-group">
          <input
            type="email"
            placeholder="Enter your email"
            className="cs-email-input"
            aria-label="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="cs-button-submit">
            BOOK YOUR TRIAL FLIGHT TODAY
          </button>
        </form>
        {isSent && <p className="cs-success-message">Email sent successfully!</p>}
      </div>

      {/* Background Image */}
      <picture className="cs-picture">
        <source
          media="(max-width: 600px)"
          srcSet="/heroimage.webp"
        />
        <source
          media="(min-width: 601px)"
          srcSet="heroimage.webp"
        />
        <img
          loading="lazy"
          decoding="async"
          src="heroimage.webp"
          alt="mountains"
          width="2250"
          height="1500"
          aria-hidden="true"
        />
      </picture>
    </section>
  );
};

export default Hero;
