import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from "./componants/Navigation"; // Keep Navigation as it loads immediately
import Hero from "./componants/Hero"; // Assuming Hero is above the fold and loads immediately
import Footer from './componants/Footer'; // Footer might be static for all pages
import { useInView } from 'react-intersection-observer'; // For viewport detection
import "./App.css";

// Lazy-loaded components
const Services = lazy(() => import("./componants/Services"));
const Pricing = lazy(() => import("./componants/Pricing"));
const Contact = lazy(() => import('./componants/Contact'));

// Wrapper to handle lazy loading and rendering on visibility
function LazyLoad({ children }) {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div ref={ref}>
      {inView && (
        <Suspense fallback={<div>Loading...</div>}>
          {children}
        </Suspense>
      )}
    </div>
  );
}

function Home() {
  return (
    <>
      <Navigation />
      <Hero />
      {/* Lazy load Services */}
      <LazyLoad>
        <Services />
      </LazyLoad>
      {/* Lazy load Pricing */}
      <LazyLoad>
        <Pricing />
      </LazyLoad>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={
          <>
            <Navigation />
            <LazyLoad>
              <Contact />
            </LazyLoad>
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
